<template>
    <b-overlay :show="loading">
    
        <b-card
        >
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="asset_mutations"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
              >
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ (row.index + 1) }}
                  </strong>
                </template>
    
                <template #cell(dari)="{item}">
                  <strong v-if="item.dari_gudang">{{ item.dari_gudang.nama_gudang }}</strong>
                  <i class="text-danger" v-else>Tujuan awal tidak ditemukan</i>
                </template>
                <template #cell(nama)="{item}">
                  <strong v-if="item.asset">{{ item.asset.nama }}</strong>
                  <i class="text-danger" v-else>Aset tidak ditemukan</i>
                </template>
                <template #cell(satuan)="{item}">{{item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-'}}</template>
                <template #cell(terima)="{item}">
                    <b-badge v-if="item.terima == 1" variant="light-success">
                        Sudah diterima
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Belum diterima
                    </b-badge>
                </template>
      
                <template #cell(actions)="{item}">
                    <b-button variant="outline-info" class="mr-1" size="sm" @click.prevent="$router.push(`/asset-mutasi-entry/detail/${item.id}`)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
    </b-overlay>
  </template>
  
  <script>
  import Ripple from 'vue-ripple-directive'
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,
    BOverlay,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  } from "bootstrap-vue";
  
  export default {
    components: {
        BCard,
      BCardActions,ToastificationContent,
      VBTooltip,
      BModal,
      BDropdown,
      BDropdownItem,
      BTable,
      BOverlay,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        loading: false,
        id:null,
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "dari", label: "Lokasi Awal Mutasi", sortable: true },
          { key: "nama", label: "Aset", sortable: true },
          { key: "jumlah", label: "Jumlah Mutasi", sortable: true },
          { key: "satuan", label: "Satuan" },
          { key: "terima", label: "Status" },
          { key: "actions", label: "Aksi" }
        ],
        asset_mutations: [],
        form: {
            dari: null,
            tujuan: null,
            asset_id: null,
            jumlah: 0,
            penyimpanan_dari: null
        }
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    methods: {
      async getMutationEntry() {
        const params = {order: 'desc'}
        if(this.isAdminGudang || this.isAdminAsset) {
          params.tujuan = this.myGudang.id
        }
        this.asset_mutations = await this.$store.dispatch('asset/getEntryMutation', params)
        this.totalRows = this.asset_mutations.length
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getGudang() {
        await this.$store.dispatch('gudang/getData', {jenis: 1})
      }
    },
    async created() {
        this.loading = true
        this.getMutationEntry()
        await this.getGudang()
        this.loading = false
    }
  };
  </script>
  