var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.asset_mutations,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(dari)",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.dari_gudang ? _c('strong', [_vm._v(_vm._s(item.dari_gudang.nama_gudang))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Tujuan awal tidak ditemukan")])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.asset ? _c('strong', [_vm._v(_vm._s(item.asset.nama))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Aset tidak ditemukan")])];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(_vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-'))];
      }
    }, {
      key: "cell(terima)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.terima == 1 ? _c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Sudah diterima ")]) : _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Belum diterima ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "outline-info",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/asset-mutasi-entry/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }